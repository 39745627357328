import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  input,
  InputSignal,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from '../../services/seo/json-ld.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, FastSvgComponent],
  animations: [
    trigger('menu', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(.95)',
        }),
        animate(
          '150ms ease-in',
          style({
            opacity: 1,
            transform: 'scale(1)',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms ease-out',
          style({
            opacity: 0,
            transform: 'scale(.95)',
          })
        ),
      ]),
    ]),
  ],
})
export class DropdownComponent implements OnInit {
  public text: InputSignal<string | undefined> = input<string>();
  public button: InputSignal<boolean> = input<boolean>(true);
  public inlineBlock: InputSignal<boolean> = input<boolean>(true);
  public menuOpen: WritableSignal<boolean> = signal(false);
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);

  ngOnInit(): void {
    this.svgRegistry.fetchSvgs('icons/chevron-up', 'icons/chevron-down');

    this.jsonLdService.addHeroiconImage('icons/chevron-up');
    this.jsonLdService.addHeroiconImage('icons/chevron-down');

    this.jsonLdService.inject();
  }

  @HostListener('click', ['$event'])
  private clickInside(event: PointerEvent): void {
    event.stopPropagation();
    this.toggleMenu();
  }

  @HostListener('document:click')
  private clickOutside(): void {
    this.toggleMenu(true);
  }

  private toggleMenu(force: boolean = false, forceValue: boolean = false): void {
    if (force) {
      this.menuOpen.set(forceValue);
    } else {
      this.menuOpen.set(!this.menuOpen());
    }
  }
}
